import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Routes as Switch, Route, Navigate} from "react-router-dom";
import {Page} from "./pages/Page";
function App() {
  return (
      <Router>
        <Switch>
            <Route path={'/'} element={<Page />}></Route>
            <Route path={'/:id'} element={<Page />}></Route>
        </Switch>
      </Router>
  );
}

export default App;
