import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {urls} from "../utils/Urls";

export const Page = () => {
    const id = useParams()['id'];
    const [mesaj, setMesaj] = useState('Buna ziua!')
    function getLinkById(idToFind) {
        const foundObject = urls.find(obj => obj.id === idToFind);
        if (id === undefined) {
            return
        }
        if (foundObject) {
            window.location.href = foundObject.url;
        } else {
            setMesaj("Nu exista acest link!")
        }
    }
    useEffect(() => {
        getLinkById(id)
    }, []);
    return(
        <div>{mesaj}</div>
    )
}